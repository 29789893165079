@font-face {
  font-family: "Nichrome";
  src: local("Nichrome"),
    url(./fonts/Nichrome/Nichrome0.1-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Nichrome Black";
  src: local("Nichrome Black"),
    url(./fonts/Nichrome/Nichrome0.1-Black.otf) format("opentype");
}

@font-face {
  font-family: "Object Sans Thin";
  src: local("Object Sans Thin"),
    url(./fonts/ObjectSans/ObjectSans-Thin.otf) format("opentype");
}

:root {
  --orange: #e3aa80;
  --darker-orange: #b68866;
  --red: #e38080;
  --darker-red: #b66666;
  --blue: #2679cd;
  --darker-blue: #1e61a4;
  --green: #3b9688;
  --darker-green: #2f786d;
  --black: #000000;
  --text: var(--black);
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--black);
}

a {
 color: var(--black); 
}

body.panelOpen {
  height: 100vh;
  overflow-y: hidden;
}

h1 {
  font-family: "Nichrome", sans-serif;
  padding: 0px;
  margin: 0px;
  font-size: 96px;
  line-height: 112px;
}

h2 {
  font-family: "Nichrome", sans-serif;
  padding: 0px;
  margin: 0px;
  font-size: 80px;
  line-height: 112px;
}
h3 {
  font-family: "Nichrome", sans-serif;
  padding: 0px;
  margin: 0px;
  font-size: 48px;
  line-height: 56px;
}

p {
  font-family: "Object Sans Thin";
  font-size: 24px;
  line-height: 32px;
}

@keyframes turn {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes popIn {
  from {
    bottom: -60px;
  }
  to {
    bottom: 24px;
  }
}

@keyframes popOut {
  from {
    bottom: 24px;
  }
  to {
    bottom: -60px;
  }
}
